@import "@elastic/eui/dist/eui_theme_light.css";
@import url("https://fonts.googleapis.com/css?family=Roboto+Mono:400,400i,700,700i");
@import url("https://rsms.me/inter/inter-ui.css");

.error {
  background-color: rgb(255, 241, 241);
  color: red;
  padding: 1em;
  font-size: 0.9em;
}

.side-bar {
  top: 98px;
}

.modal {
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(5px);

  .modal-content {
    position: relative;
    background-color: #fafbfd;
    border: 1px solid #d3dae6;
    height: 90vh;
    margin: 2em;
    padding: 2em;
    overflow: auto;

    .close {
      position: absolute;
      padding: 1em;
      top: 0;
      right: 0;
    }

    .modal-footer {
      text-align: right;
      button {
        margin-left: 2em;
      }
    }
  }
}

.question {
  background-color: #fff;
  border: 1px solid #d3dae6;
  padding: 1em;
  margin-bottom: 1em;

  .question-text {
    margin-bottom: 1em;
  }
  .question-choice {
    display: flex;
    margin-bottom: 0.5em;

    label {
      margin-left: 0.5em;
      cursor: pointer;

      &:hover {
        color: #006bb4;
      }
    }

    input {
      cursor: pointer;
    }
  }
}

.form-field-error {
  margin-top: 0.5em;
  margin-bottom: 1.2em;
  color: red;
  font-size: 0.8em;
}

.custom-table {
  font-size: 0.9em;
  border: 1px solid #d3dae6;
  th,
  td {
    border: 1px solid #d3dae6;
    padding: 3px 6px;
  }
}
